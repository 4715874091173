.App {
	position: relative;
	height: 100vh;
	overflow-x: hidden;
	overflow-x: clip;
}
#root > .intro {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 10%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.qrcode-scanner {
	margin-bottom: 30px;
}
.qrcode-scanner video {
	position: absolute;
	left: 0;
}
.qrcode-scanner .spinner {
	position: relative;
    top: 0;
    bottom: 0;
    height: 100%;
}

header nav.header {
	height: 42px;
	background: #666;
	width: 100%;
	margin: 0 auto;
	padding-left: 2.5%;
	padding-right: 2.5%;
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: 10;
}
nav.header a {
	height: 42px;
}
nav.header svg {
	width: 32px;
	height: 19px;
}
nav.header .title {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	color: white;
}
.shopping-cart {
    margin-left: auto;
}
.shopping-cart button:not(:last-child) {
    margin-right: 16px;
}
.shopping-cart .cart-detail-link svg {
    font-size: 24px;
}
.shopping-cart .cart-detail-link .cart-article {
    color: black;
    font-size: 60%;
    margin-top: -16px;
    margin-left: 0px;
    font-weight: 700;
    background: white;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}
.shopping-cart .cart-detail-link {
    height: 50px;
    width: 60px;
}

main {
	text-align: center;
	margin: 0 auto;
	position: relative;
	padding-top: 42px;
	max-width: 600px;
}

/* NOTIFICATIONS */
footer .notification {
	position: fixed;
	bottom: 5%;
	right: 10%;
	background: #666;
	height: 52px;
	width: 52px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 24px;
	cursor: pointer;
	z-index: 9;
}
footer .notifications {
	background: #666;
	z-index: 10;
	color: white;
	bottom: calc(5% + 35px);
	position: fixed;
	right: 10%;
	left: 10%;
	border-radius: 5px;
	padding: 5px;
	max-height: 80vh;
	overflow-x: hidden;
	overflow-y: scroll;
}
footer .notifications a {
	color: white;
	text-decoration: underline;
	font-size: 1rem;
}
footer .notifications .note.new {
	font-weight: bold;
	font-style: italic;
}
footer .notification .new-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: white;
	transform: translate(25%,-25%);
	background: #666;
	border-radius: 50px;
	line-height: 50%;
	padding: 10px;
}
footer .notifications .note:not(:last-child) {
	margin-bottom: 16px;
}
footer .notifier {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
footer .notifier * {
	color: white;
}
footer .notifier .close {
	background: #333;
	height: 35px;
	width: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	position: absolute;
	top: 0;
	right: 0;
	border: 1px solid white;
	transform: translate(50%, -50%);
	cursor: pointer;
	-webkit-transform: translate(50%, -50%);
	-moz-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	-o-transform: translate(50%, -50%);
}
footer .notifier > form {
	position: relative;
	background: #333;
	padding: 5%;
	width: 80%;
}
footer .notifier .access input[type="password"] {
	border-bottom: 1px solid white;
	margin-right: 12px;
	height: 32px;
}
footer .notifier .message textarea {
	background: white;
	display: block;
	width: 100%;
	color: black;
	font-size: 18px;
	padding: 5px;
	border: 1px solid #333;
}

.logo {
	width: calc(160/300*100%);
	min-width: 160px;
	max-width: 220px;
	padding-bottom: 32px;
}

.date {
	padding-bottom: calc(8/300*100%);
}

.weather {
	padding-bottom: calc(16/300*100%);
}
.weather .weather-details:not(:last-child) {
	margin-right: calc(32/300*100%);
}
.weather img {
	width: 70px;
}
.weather .weather-warning {
	padding: 16px 20% 0;
}

nav.main .link {
	margin: 2%;
	width: 40vw;
	max-width: 120px;
	height: 40vw;
	max-height: 120px;
}
nav.main .circle {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	box-shadow: 0px 1px 8px rgba(0,0,0,0.1);
}
nav.main li:nth-child(8n+1) .circle {
	background: rgba(183, 230, 243, 0.5);
}
nav.main li:nth-child(8n+2) .circle {
	background: rgba(252, 230, 211, 1);
}
nav.main li:nth-child(8n+3) .circle {
	background: rgba(255, 232, 234, 1);
}
nav.main li:nth-child(8n+4) .circle {
	background: rgba(255, 240, 190, 0.7);
}
nav.main li:nth-child(8n+5) .circle {
	background: rgba(111, 207, 151, .5);
}
nav.main li:nth-child(8n+6) .circle {
	background: #edece8;
}
nav.main li:nth-child(8n+7) .circle {
	background: #e2d4d4;
}
nav.main li:nth-child(8n+8) .circle {
	background: #adddcf;
}
nav.main svg {
	width: 25px;
	height: 25px;
}
nav.main .title {
	padding-top: calc(32/300*100%);
}

.popup {
	position: fixed;
	background: white;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10;
	padding: 10%;
}
.popup h1 {
	padding-bottom: 24px;
}
.popup p {
	padding-bottom: 16px;
}
.popup .surname {
	text-transform: capitalize;
}

.guestmap .entries > div {
	padding-bottom: 48px;
}
.guestmap .entries .title {
	padding-bottom: 16px;
}
/* bread order */
.bread .breadOrder {
	padding-top: 50px;
}
.bread .breadOrder .date {
	padding-bottom: 50px;
}
.bread .breadOrder .product {
	margin-bottom: 32px;
	padding-bottom: 32px;
	border-bottom: 1px solid #333;
}
.bread .breadOrder .bread-list .product {
	width: 48%;
}
.bread .breadOrder .count {
	width: 50px;
}
.bread .breadOrder .product-title {
	padding: 16px 0px 8px;
	hyphens: auto;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
}
.bread .breadOrder fieldset .product-price {
	padding-bottom: 8px;
}
.bread .breadOrder .qnt {
	padding-top: 16px;
}
.bread .apartment-title {
	margin-bottom: 16px;
}
.bread .apartment button {
	margin: 12px;
}
.bread .checkout {
	margin: 40px auto;
}
.check-out .icon {
	font-size: 150px;
}
.breadOrder .repeat-order {
    padding-top: 32px;
}
.breadOrder .repeat-order input {
    height: 22px;
    width: 22px;
    margin: 0px 12px;
}

.forecast .day {
	padding-bottom: 40px;
	padding-left: 5%;
	padding-right: 5%;
}
.forecast .weatherDate {
	padding-bottom: 8px;
}
.forecast img {
	width: 100px;
}
.forecast .weatherBulletin {
	padding-top: 16px;
}

/* EVENT */
.events .events-intro {
	padding-bottom: 40px;
}
.events .event {
	padding-bottom: 80px;
}
.events .event-time {
	padding-bottom: 12px;
}
.events .event-name {
	padding-bottom: 24px;
}
.events .event-desc {
	padding-bottom: 16px;
}

/* SHOP */
.shop .intro-text {
	padding-bottom: 60px;
}
.shop .product {
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 2px solid black;
	width: 100%;
}
.shop img {
	height: 220px;
}
.shop .product-desc {
	padding-bottom: 32px;
}
.shop .product-desc.short,
.short p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.read-more {
	text-decoration: underline;
	display: block;
	margin: 0 auto;
}
.product-list .product-sale {
	width: 50px;
	height: 50px;
}

/* FAVORITS */
.favorits .favorit {
	padding-bottom: 40px;
	margin-bottom: 40px;
	width: 100%;
}
.favorits .favorit-subtitle {
	padding-top: 12px;
}
.favorits img {
	max-width: 100%;
	margin-top: 32px;
}
.favorits .favorit-text {
	margin-top: 32px;
}

/* Restaurants */
.restaurants .restaurant {
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 2px solid black;
	width: 100%;
}
.restaurants .restaurants-intro {
	padding-bottom: 40px;
}
.leaflet-container {
	width: 100%;
	height: calc(3/2*100vw);
	max-height: 380px;
	margin-top: 12px;
}
.leaflet-control-attribution {
	display: none;
}

/* Paypal */
.paypal .paypal-form {
	padding: 5%;
}
.paypal .paypal-form label {
	display: block;
	padding-top: 24px;
}
.paypal .paypal-form input.desc,
.paypal .paypal-form input.price {
	min-width: 270px;
	border-bottom: 1px solid #333;
	margin: 0 auto;
	padding-top: 10px;
	text-align: center;
	margin-bottom: 10px;
}
.paypal .paypal-form input.price {
	min-width: auto;
	width: 100px;
}
.paypal .paypal-buttons {
	max-width: 320px !important;
	margin-top: 24px;
}

/* RENTAL E-BIKE */
.rental-service {
	padding: 5%;
}
.rental-service .rental {
	width: 100%;
}
.rental-service .rental-list .product:not(:last-child) {
	border-bottom: 1px solid #333;
	padding-bottom: 32px;
	margin-bottom: 32px;
}
.rental-service .rental-image {
    max-width: 220px;
    margin: 24px 0;
}
.rental-service .rental-list .rental:not(:last-child) {
	border-bottom: 1px solid #333;
	padding-bottom: 32px;
	margin-bottom: 32px;
}
.price-box {
    position: relative;
    border: 1px solid #333;
    display: inline-block;
    padding: 8px 32px;
	margin-top: 16px;
}
.react-datepicker__day--excluded {
	background-color: red !important;
	color: white !important;
}
.react-datepicker .react-datepicker__day--highlighted.react-datepicker__day--disabled {
	background-color: unset;
}
.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
	width: 1.7rem !important;
	line-height: 1.7rem !important;
}
.react-datepicker__input-container {
	display: flex;
	cursor: pointer;
}
.react-datepicker__input-container input {
	text-align: center;
	cursor: pointer;
	color: white;
}
.react-datepicker__input-container input::placeholder {
	color: white;
	text-transform: uppercase;
	font-weight: 700;
}
.rental-service .selection {
	padding-top: 32px;
}
.rental-service .selection .selection-date {
	margin: 16px 8px;
}
.rental-service .datepicker .select-date {
	margin-left: 8px;
	margin-right: 8px;
}
.rental-list .read-more {
    text-decoration: underline;
}

/* MERANO MAPS API */
.merano-maps .filter {
	padding: 12px 0px;
    position: sticky;
    top: 40px;
    background: white;
	overflow-x: scroll;
	z-index: 10;
	margin-left: -5%;
    margin-right: -5%;
    padding-left: 2.5%;
}
.merano-maps .filter select {
	background: #333;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
    padding: 4px;
}
.merano-maps .filter select:not(:last-child) {
	margin-right: 8px;
}
.merano-maps .filter .search {
	padding: 0;
	margin-right: 8px;
	background: #333;
    border-radius: 24px;
    color: white;
}
.merano-maps .filter .search svg {
	height: 26px;
    width: 19px;
    margin: 0 4px;
    padding: 4px 0;
}
.merano-maps .filter .search input {
	width: 0;
	transition: width .3s ease-out;
	-webkit-transition: width .3s ease-out;
	-moz-transition: width .3s ease-out;
	-ms-transition: width .3s ease-out;
	-o-transition: width .3s ease-out;
	background: #333;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
}
.merano-maps .filter .search.open input {
	width: 140px;
}
.merano-maps .filter .search input::-webkit-input-placeholder {
	background: #333;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
}
.merano-maps .filter .search input::-moz-placeholder {
	background: #333;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
}
.merano-maps .filter .search input:-ms-input-placeholder {
	background: #333;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
}
.merano-maps .filter .search input::-ms-input-placeholder {
	background: #333;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
}
.merano-maps .entries .entry {
	padding: 20px 0;
	border-bottom: 1px solid #999;
}
.merano-maps .entries .entry .details {
	padding-top: 12px;
}
.merano-maps .entries .entry .details > *:not(:last-child) {
	margin-right: 12px;
}
.merano-maps .entries .entry .details svg {
	margin-right: 4px;
}
.merano-maps .desc-wrapper {
	padding-top: 24px;
}
.merano-maps .cta > *:not(:last-child) {
	margin-right: 12px;
}