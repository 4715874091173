html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
/* http://de.learnlayout.com/box-sizing.html */
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
body, html {
	height: 100%;
	min-height: 100%;
	font-size: 24px;
	line-height: calc(32rem / 24);
	color: #666;
}
@media (max-width: 425px) {
	body, html {
		font-size: 19px;
	}
}
@media (max-width: 768px) and (min-width: 426px) {
	body, html {
		font-size: 21px;
	}
}
@media (max-width: 1024px) and (min-width: 769px) {
	body, html {
		font-size: 23px;
	}
}
body {
	overflow-x: hidden;
	background-color: #ffffff;
	width: inherit;
	height: inherit;
	font-family: 'Thasadith';
	font-weight: 400;
}
body, table, input, select, textarea, pre {
	font-family: 'Thasadith';
	color: #666;
	font-weight: 400;
}

/*--------------------------------------------- TYPOGRAPHY ---------------------------------------------*/

.sans {
	font-family: 'Thasadith';
}
.serif {
	font-family: 'Amiri';
}
.handwriting {
	font-family: 'Parisienne';
}
h1, .t1, .t1 a {
	font-family: 'Amiri';
	font-size: calc(28rem / 24);
	line-height: calc(32rem / 24);
	color: #999;
	font-weight: normal;
	text-transform: none;
	font-style: normal;
	letter-spacing: 0rem;
}
@media (max-width: 1024px){
	h1, .t1, .t1 a {
		font-size: calc(25rem / 24)
	}
}
.t2, .t2 a {
	font-family: "Amiri";
	font-size: calc(24rem / 24);
	line-height: calc(30rem / 24);
	color: #999;
	font-weight: normal;
	text-transform: none;
	font-style: normal;
	letter-spacing: 0rem;
}
.t3, .t3 a {
	font-family: "Amiri";
	font-size: calc(20rem / 24);
	line-height: calc(24rem / 24);
	color: #999;
	font-weight: normal;
	text-transform: none;
	font-style: normal;
	letter-spacing: 0rem;
}
.t4, .t5 a {
	font-family: 'Thasadith';
	font-size: calc(20rem / 24);
	line-height: calc(24rem / 24);
	color: #999;
	font-weight: 700;
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: calc(2rem / 24);
}
.t5, .t6 a {
	font-family: 'Thasadith';
	font-size: calc(15rem / 24);
	line-height: calc(24rem / 24);
	color: #999;
	font-weight: 700;
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: calc(1.5rem / 24);
}
.t6, .t7 a {
	font-family: "Amiri";
	font-size: calc(24rem / 24);
	line-height: calc(32rem / 24);
	color: #999;
	font-weight: normal;
	text-transform: none;
	font-style: normal;
	letter-spacing: 0rem;
}
.large {
	font-size: calc(32rem / 24);
	line-height:calc(40rem / 24);
}
.medium {
	font-size: calc(24rem / 24);
	line-height: calc(32rem / 24);
}
.default, p, li, a, span, legend, label, input, select, textarea {
	color: #666;
	font-size: calc(18rem / 24);
	line-height: calc(28rem / 24);
}
.default {
	font-style: normal;
	font-weight: 400;
}
.small {
	font-size: calc(15rem / 24);
	line-height: calc(24rem / 24);
}
i {
	font-size: 40px;
	line-height: 40px;
	color: #999;
}
a {
	text-decoration: none;
}
.c4 {color:white;}
a, button, select, div, input, textarea, article, span {
	outline: none;
	text-decoration: none;
	background-color: transparent;
	border: 0;
	padding: 0;
}
.button1, .button2, .button3,
.button4, .button5, .button6,
.button7 {
	position: relative;
	cursor: pointer;
	display: inline-block;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.button1 {
	font-family: 'Thasadith';
	letter-spacing: calc(1.5rem / 24);
	font-style: normal;
	text-align: center;
	box-sizing: border-box;
	padding: 6px 12px;
	z-index: 1;
	background: #999;
    color: white;
    border: 2px solid #333;
    border-radius: 3px;
	margin-top: 24px;
}
.button2 {
	font-family: 'Thasadith';
	letter-spacing: calc(1.5rem / 24);
	font-style: normal;
	text-align: center;
	box-sizing: border-box;
	padding: 6px 12px;
	z-index: 1;
	background: white;
    color: #333;
    border: 2px solid #999;
    border-radius: 3px;
	margin-top: 24px;
}
.button2.active {
	color: white;
	background: #999;
}
.error {
	color: firebrick;
	font-weight: bold;
}

/******************************** STYLEGUIDE *************************************/
.center {
	margin: 0 auto;
}
.flex {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
.flex-center {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	justify-content: center;
}
.flex-column {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
}
.flex-wrap {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-wrap: wrap;
}
.justify-center {
	justify-content: center;
}
.space-between {
	justify-content: space-between;
}
.align-center {
	align-items: center;
}
.block {
	display: block;
}
.none {
	display: none;
}
.tac {
	text-align: center;
}
.tal {
	text-align: left;
}
.tar {
	text-align: right;
}

.spinner {
	animation-name: spin;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	position: fixed;
    top: calc(50vh - 1.5em);
    left: calc(50% - .5em);
    font-size: 48px;
	z-index: -1;
}
@-ms-keyframes spin {
	from { -ms-transform: rotate(0deg); }
	to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
	from { -moz-transform: rotate(0deg); }
	to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}